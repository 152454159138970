import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { FormatDate } from '../utils';
import flagSlice from '../images/flag-slice.svg';
import Breadcrumbs from './Breadcrumbs';
import { Container } from './ui';

const StyledNewsResourcesSingle = styled.section`
  ${sectionMargins('100px', '150px')}
  position: relative;
`;

const StyledContainer = styled(Container)`
  max-width: 1000px;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  top: -100px;
  margin-left: 30px;

  ${minBreakpointQuery.small`
    top: -115px;
  `}

  ${minBreakpointQuery.medium`
    top: -130px;
  `}
  
  ${minBreakpointQuery.large`
    top: -140px;
  `}

  ${minBreakpointQuery.xxlarge`
    top: -150px;
  `}
`;

const StyledOuter = styled.div``;

const StyledInfo = styled.span`
  white-space: break-spaces;
`;

const StyledHeading = styled.h1`
  font-weight: ${fontWeights.bold};
  ${fontSize(28)};
  margin: 40px 0;
`;

const StyledArticle = styled.article`
  blockquote {
    position: relative;
    font-weight: ${fontWeights.bold};
    ${fluidFontSize(
      '20px',
      '46px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
    line-height: 26px;
    margin: 10px 0;

    ${minBreakpointQuery.medium`
      line-height: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    `}

    ${minBreakpointQuery.xlarge`
      line-height: 54px;
      margin-top: 30px;
      margin-bottom: 30px;
    `}

    ${maxBreakpointQuery.xlarge`
      margin-left: 30px;
    `}

    &:before {
      content: '';
      display: block;
      background: url(${flagSlice});
      width: 30px;
      height: 100%;
      position: absolute;
      left: -64px;
      background-size: cover;

      ${minBreakpointQuery.xlarge`
        width: 42px;
      `}
    }
    p {
      line-height: 1.4;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 12px;
    font-weight: ${fontWeights.medium};
    line-height: 1.5;
    ${minBreakpointQuery.small`
      margin-bottom: 14px;
    `}
  }
  h2 {
    ${fontSize(26)};
  }
  h3 {
    ${fontSize(24)};
  }
  h4 {
    ${fontSize(22)};
  }
  h5 {
    ${fontSize(20)};
  }
  h6 {
    ${fontSize(18)};
  }
  p {
    margin-bottom: 20px;
    line-height: 1.5;
    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${brandColours.primary};
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
  ul,
  ol {
    margin-bottom: 20px;
    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}
    &:last-child {
      margin-bottom: 0;
    }
    li {
      margin-bottom: 10px;
      line-height: 1.5;
      ${minBreakpointQuery.small`
        margin-bottom: 12px;
      `}
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul {
    li {
      position: relative;
      padding-left: 20px;
      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-color: ${brandColours.primary};
        border-radius: 50%;
      }
    }
  }
  ol {
    list-style-type: decimal;
    li {
      margin-left: 16px;
    }
  }
`;

const StyledImageWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 60px;
    margin-bottom: 60px;
  `}
`;

const StyledCaption = styled.p`
  margin-top: 20px;
  font-weight: ${fontWeights.bold};
`;

const StyledPlayerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin-top: 20px;
  margin-bottom: 20px;

  ${minBreakpointQuery.medium`
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 60px;
    margin-bottom: 60px;
  `}
`;

const StyledPlayer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const NewsResourcesSingle = ({
  item,
  heading,
  date,
  category,
  breadcrumbs,
}) => {
  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const { gatsbyImageData, title, description } = node.data.target;
        if (!gatsbyImageData) {
          // asset is not an image
          return null;
        }

        return (
          <StyledImageWrapper>
            <GatsbyImage image={gatsbyImageData} alt={title} />
            {description && <StyledCaption>{description}</StyledCaption>}
          </StyledImageWrapper>
        );
      },
      'embedded-entry-block': node => {
        const { __typename } = node.data.target;

        if (__typename === 'ContentfulVideo') {
          const { url } = node.data.target;

          return (
            <StyledPlayerWrapper>
              <StyledPlayer
                src={`https://www.youtube.com/embed/${
                  url.split('v=')[1]
                }?&autoplay=1`}
              />
            </StyledPlayerWrapper>
          );
        }
      },
    },
  };

  return (
    <StyledNewsResourcesSingle>
      <StyledContainer>
        <StyledBreadcrumbs
          breadcrumbs={breadcrumbs}
          currentPageTitle={heading}
        />
        <StyledOuter>
          <StyledInfo>{`${FormatDate(date)}  /  ${category}`}</StyledInfo>
          <StyledHeading>{heading}</StyledHeading>
          <StyledArticle>{item && renderRichText(item, options)}</StyledArticle>
        </StyledOuter>
      </StyledContainer>
    </StyledNewsResourcesSingle>
  );
};

export default NewsResourcesSingle;
