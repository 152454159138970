import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import NewsCards from '../components/NewsCards';
import NewsResourcesSingle from '../components/NewsResourcesSingle';

const NewsResourcesSingleTemplate = ({
  data: {
    contentfulNewsResourcesArchive: { title, slug },
    contentfulNewsResource,
    allContentfulNewsResource: { nodes },
  },
}) => (
  <Layout>
    <main>
      <NewsResourcesSingle
        item={contentfulNewsResource.content}
        category={contentfulNewsResource.category.singularForm}
        heading={contentfulNewsResource.title}
        date={contentfulNewsResource.date}
        breadcrumbs={[{ text: title, slug: slug }]}
      />
      <NewsCards heading={'Similar stories'} items={nodes} />
    </main>
  </Layout>
);

export const NewsResourcesSingleTemplateQuery = graphql`
  query NewsResourcesSingleTemplateQuery($id: String!) {
    contentfulNewsResourcesArchive {
      title
      slug
    }
    contentfulNewsResource(id: { eq: $id }) {
      title
      slug
      category {
        singularForm
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
          ... on ContentfulVideo {
            contentful_id
            __typename
            url
          }
        }
      }
      metaDescription
      date
    }
    allContentfulNewsResource(
      sort: { fields: date, order: DESC }
      limit: 4
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(width: 800, height: 528)
        }
        pdf {
          title
          url
        }
        excerpt
      }
    }
  }
`;

export default NewsResourcesSingleTemplate;

export const Head = ({
  data: {
    contentfulNewsResource: { title, metaDescription },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};
