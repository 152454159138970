export const FormatDate = date => {
  let year = parseInt(date.split('-')[0]);
  let month = parseInt(date.split('-')[1]);
  let day = parseInt(date.split('-')[2]);

  switch (month) {
    case 1:
      month = 'January';
      break;
    case 2:
      month = 'Febuary';
      break;
    case 3:
      month = 'March';
      break;
    case 4:
      month = 'April';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'June';
      break;
    case 7:
      month = 'July';
      break;
    case 8:
      month = 'August';
      break;
    case 9:
      month = 'September';
      break;
    case 10:
      month = 'October';
      break;
    case 11:
      month = 'November';
      break;
    default:
      month = 'December';
  }

  return `${day} ${month} ${year}`;
};
